export const baseChatBotSchema = {
  type: "object",
  properties: {
    entityType: { type: "string", const: "chatbot" },
    organizationId: { type: "string" },
    entityId: { type: "string" },
    name: { type: "string" },
    botType: { type: "string", enum: ["academic", "departmental", "personal", "concept"] },
    config: {
      type: "object",
      properties: {
        googleDrive: {
          type: "object",
          properties: {
            botFolderId: { type: "string" }
          },
          required: ["botFolderId"]
        },
        postgres: {
          type: "object",
          properties: {
            name: { type: "string" },
            host: { type: "string" },
            port: { type: "string" },
            user: { type: "string" },
            schema: { type: "string" },
            collection: { type: "string" }
          },
          required: ["name", "host", "port", "user", "schema", "collection"]
        },
        intro: {
          type: "object",
          properties: {
            greeting: { type: "string" },
            followup1: { type: "string" },
            followup2: { type: "string" },
            name: { type: "string" }
          },
          required: ["greeting", "followup1", "followup2"]
        },
        llm: {
          type: "object",
          properties: {
            deploymentName: { type: "string" },
            docsToRetrieve: {type: "integer"},
            temperature: { type: "number" },
            distanceDelta: { type: "number" },
            distanceCeiling: { type: "number" },
            embeddingDeploymentName: { type: "string" },
            apiBase: { type: "string" },
            apiVersion: { type: "string" },
            apiType: { type: "string" },
            maxMemory: {type: "integer"},
            maxTokens: {type: "integer"},
          }
        },
        prompt: {
          type: "object",
          properties: {
            text: { type: "string" },
            normalPrompt: { type: "string" },
            quietPrompt:  { type: "string" },
            quietModeActivated: { type: "boolean" }
          },
          required: ["text"]
        },
        webScraper: {
          type: "object",
          properties: {
            urlToScrape: { type: "string" },
            urlToNotScrape: { type: "string" },
            enableAiIntentGen: { type: "boolean" },
            enableIngest: { type: "boolean" },
            forceScrape: { type: "boolean" },
            forceClearAndReingest: { type: "boolean" },
            enableSpellChecking: { type: "boolean" },
            preferredSourceType: { type: "string" },
            scrapeTime: { type: "string" },
            lastScrape: {type: "string"}
          },
          required: ["urlToScrape", "urlToNotScrape", "enableAiIntentGen", "enableIngest", "forceScrape", "forceClearAndReingest", "enableSpellChecking", "preferredSourceType"]
        },
        genConfig: {
          type: "object",
          properties: {
            logQuestions: {type: "boolean"},
            thumbsDownEmail:{type:"boolean"}
          }
        }
      },
      required: ["postgres", "intro", "prompt", "webScraper","googleDrive"]
    },
    version: { type: "integer", minimum: 1 }
  },
  required: ["organizationId", "entityType", "config", "version"],
  additionalProperties: false
};

export const createChatBotSchema = {
  ...baseChatBotSchema
};

export const updateChatBotSchema = {
  ...baseChatBotSchema,
  required: [...baseChatBotSchema.required, "entityId"]
};
