// Organization model class
export class Organization {
  constructor(data) {
    this.entityType = "organization";
    this.entityId =  data.entityId.toUpperCase();
    this.name = data.name;
    this.version = data.version || 1;
  }

  static fromObject(obj) {
    return new Organization(obj);
  }
}
