// Chatbot model class
export class Chatbot {
  constructor(data) {
    this.entityType = "chatbot";
    this.organizationId = data.organizationId.toUpperCase();
    this.entityId = data.entityId.toUpperCase();
    this.name = data.name;
    this.botType = data.botType;
    this.config = data.config;
    this.version = data.version || 1;
  }

  static fromObject(obj) {
    return new Chatbot(obj);
  }
}
