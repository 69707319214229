import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";
import { KMSClient, DecryptCommand } from "@aws-sdk/client-kms";
import { unmarshall } from "@aws-sdk/util-dynamodb";

export class CredentialStore {
  constructor() {
    this.dynamodbClient = new DynamoDBClient({ region: "us-east-1" });
    this.kmsClient = new KMSClient({ region: "us-east-1" });

    this.config = this.getConfig();
    this.credstoreTablename = this.config.UMD_AH_CREDSTORE_TABLENAME;

    if (!this.credstoreTablename) {
      console.log("We did not find the credential store table, so will not use cred store");
      return;
    }
  }

  getConfig() {
    return {
      UMD_AH_PRODUCTSUITE: process.env.UMD_AH_PRODUCTSUITE,
      UMD_AH_PRODUCT: process.env.UMD_AH_PRODUCT,
      UMD_AH_ENVIRONMENT: process.env.UMD_AH_ENVIRONMENT,
      UMD_AH_CREDSTORE_TABLENAME: process.env.UMD_AH_CREDSTORE_TABLENAME
    };
  }


  getProductId() {
    return `${this.config.UMD_AH_PRODUCTSUITE}-${this.config.UMD_AH_PRODUCT}`;
  }

  async getCredential(params) {
    if (!this.credstoreTablename) {
      return ["No credential store table, so will not use cred store", null];
    }

    if (!params.CredentialKey) {
      return ["Missing 'CredentialKey' parameter", null];
    }

    const product_id = this.getProductId();
    const env_full = this.config.UMD_AH_ENVIRONMENT;
    let env_val;
    if (env_full === 'dev' || env_full === 'qa' || env_full === 'prod') {
        env_val = env_full;
    } else if(env_full.startsWith('sandbox')){
      env_val = 'sandbox'
    }

    console.log(`params.CredentialKey`, params.CredentialKey)

    const ddbParams = {
      TableName: this.credstoreTablename,
      Key: {
        ProductId: { S: product_id },
        CredentialKey: { S: params.CredentialKey }
      }
    };
    console.log(`ddbParams`, ddbParams)
    try {
      const response = await this.dynamodbClient.send(new GetItemCommand(ddbParams));
      //console.log(`response:`, response)
      const item = unmarshall(response.Item);
      //console.log(`item:`, item)
      const encryptedCredential = item.EncryptedCredential;
      //console.log(`encryptedCredential`, encryptedCredential)
      if (!encryptedCredential) {
        return ["No encrypted credential found for the given key", null];
      }

      const decryptParams = {
        CiphertextBlob: Buffer.from(encryptedCredential, "base64"),
        EncryptionContext: {
          "Environment": env_val,
          "CredentialKey": params.CredentialKey,
          "ProductSuite": this.config.UMD_AH_PRODUCTSUITE,
          "Product": this.config.UMD_AH_PRODUCT
        }
      };
      //console.log(`decryptParams`,decryptParams)
      const decryptResponse = await this.kmsClient.send(new DecryptCommand(decryptParams));
      //console.log(`decryptResponse`, decryptResponse)
      const password = new TextDecoder("utf-8").decode(decryptResponse.Plaintext);
      //console.log(`password`, password);
      return [null, password];
    } catch (error) {
            console.log(error)
            return [error.message, null];
    }
  }
}

const credentialStore = new CredentialStore();
let cachedCredential = {};

async function loadCredential(credentialKey) {
  try {
    const [error, credential] = await credentialStore.getCredential({ CredentialKey: credentialKey });
    if (error) {
      console.error(`Error fetching credential:`, error);
      return null;
    }
    return credential;  // Assuming this is a map or object of orgId to token
  } catch (error) {
    console.error("Error in loadCredentials:", error);
    return null;
  }
}

// Function to ensure credentials are loaded
async function ensureCredentialLoaded(credentialKey) {
  if (!cachedCredential[credentialKey]) {
    //console.log("Loading credentials...");
    cachedCredential[credentialKey] = await loadCredential(credentialKey);
    //console.log("Credentials loaded");
  }
}

//'umd-adminui'
export const getCredentialByOrgIdAndKey = async (orgId, credentialKey) => {
  //console.log(`Getting credentials orgId:${orgId} credentialKey:${credentialKey}`);
  await ensureCredentialLoaded(credentialKey);

  //TODO: For now we only handle our one UMD org credential
  if (!cachedCredential[credentialKey]) {
    throw new Error(`Could not get the cached credential token`);
  }

  return cachedCredential[credentialKey];
};