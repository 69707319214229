import {Factory} from 'rosie';
import {SystemConfig} from "../models/SystemConfig.js";

const SystemConfigFactory = new Factory()
    .attr('entityType', 'systemConfig')
    .attr('organizationId', 'UMD')
    .attr('timestamp', () => new Date().toISOString())
    .attr('version', 1)
    .attr("config", {
        database: {
            name: {
                devConfigurable: true,
                label: "Database Name",
                type: "string",
                userConfigurable: false,
                value: "apphosting"
            },
            host: {
                devConfigurable: true,
                type: "text",
                userConfigurable: false,
                value: "rds-aurora-chatbot.it-dev-apphosting.aws.umd.edu"
            },
            port: {
                devConfigurable: false,
                type: "text",
                userConfigurable: false,
                value: "5432"
            },
            user: {
                devConfigurable: true,
                type: "text",
                userConfigurable: false,
                value: "aibot"
            },
            type: {
                devConfigurable: false,
                label: "DB Type",
                type: "string",
                userConfigurable: false,
                value: "postgres"
            }
        },
        intro: {
            greeting: {
                devConfigurable: true,
                label: "Greeting Message",
                type: "textarea",
                userConfigurable: true,
                value: "",
                sortOrder: "2"
            },
            followup1: {
                devConfigurable: true,
                label: "Follow-up Message 1",
                type: "textarea",
                userConfigurable: true,
                value: "",
                sortOrder: "3"
            },
            followup2: {
                devConfigurable: true,
                label: "Follow-up Message 2",
                type: "textarea",
                userConfigurable: true,
                value: "",
                sortOrder: "4"
            },
            name: {
                devConfigurable: true,
                label: "Bot Name",
                type: "textarea",
                userConfigurable: true,
                value: "",
                sortOrder: "1"
            }
        },
        llm: {
            config: {
                apiBase: {
                    devConfigurable: true,
                    label: "API Base (azure - https://umd-dit-gpt.openai.azure.com/ (GPT 3.5) or https://umd-dit-gpt-useast2.openai.azure.com/ (GPT 4))",
                    type: "text",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "7"
                },
                apiType: {
                    devConfigurable: true,
                    label: "API Type",
                    type: "text",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "8"
                },
                apiVersion: {
                    devConfigurable: true,
                    label: "API Version (2023-03-15-preview for 3.5, 2023-07-01-preview for 4)",
                    type: "text",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "9"
                },
                deploymentName: {
                    devConfigurable: true,
                    label: "Deployment Name (umd-gpt-35-turbo, gpt-4-0613-older - LLM to use to ask the questions)",
                    type: "text",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "2"
                },
                distanceCeiling: {
                    devConfigurable: true,
                    label: "Distance Ceiling (exclude any sources higher than this distance number)",
                    type: "number",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "5"
                },
                distanceDelta: {
                    devConfigurable: true,
                    label: "Distance Delta (exclude non-prefered sources if further than delta from best preferred source)",
                    type: "number",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "4"
                },
                docsToRetrieve: {
                    devConfigurable: true,
                    label: "Docs to Retrieve",
                    type: "number",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "3"
                },
                embeddingDeploymentName: {
                    devConfigurable: true,
                    label: "Embedding Deployment Name (text-embedding-ada-002 - model to use for vector embeddings)",
                    type: "text",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "6"
                },
                maxMemory: {
                    devConfigurable: true,
                    label: "Max Memory",
                    type: "number",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "10"
                },
                maxTokens: {
                    devConfigurable: true,
                    label: "Max Tokens",
                    type: "number",
                    userConfigurable: false,
                    value: "",
                    sortOrder: "11"
                },
                temperature: {
                    devConfigurable: true,
                    label: "Temperature",
                    type: "number",
                    userConfigurable: false,
                    value: "0.9",
                    sortOrder: "1"
                },
                //add the same map for apiType, apiVersion, deploymentName, distanceCeiling, distanceDelta, docsToRetrieve, maxMemory and maxTokens
                
            },
            "GPT35": {
                deploymentName: "umd-gpt-35-turbo",
                docsToRetrieve: 4,
                temperature: 0.7,
                distanceDelta: 0.2,
                embeddingDeploymentName: "text-embedding-ada-002",
                apiBase: "https://umd-dit-gpt.openai.azure.com/",
                apiType: "azure",
                apiVersion: "2023-03-15-preview"
            },
            "GPT4": {
                deploymentName: "gpt-4-0613-older",
                docsToRetrieve: 5,
                temperature: 0.6,
                distanceDelta: 0.15,
                embeddingDeploymentName: "text-embedding-ada-002",
                apiBase: "https://umd-dit-gpt-useast2.openai.azure.com/",
                apiType: "azure",
                apiVersion: "2023-07-01-preview"
            }
        },
        prompt: {
            text: {
                devConfigurable: true,
                label: "Text",
                type: "textarea",
                userConfigurable: false,
                value: "Dynamic question and answer based on context and history.",
            },
            normalPrompt: {
                devConfigurable: true,
                label: "Standard Prompt",
                type: "textarea",
                userConfigurable: false,
                value: "Standard interaction initiation.",
            },
            quietPrompt: {
                devConfigurable: true,
                label: "Quiet Mode Prompt",
                type: "textarea",
                userConfigurable: false,
                value: "Reduced interaction mode.",
            },
            quietModeActivated: {
                devConfigurable: true,
                label: "Quiet Mode Activation",
                type: "toggle",
                userConfigurable: true,
                value: false,
            }
        },
        general: {
            logQuestions: {
                devConfigurable: true,
                label: "Log Questions to Console",
                type: "toggle",
                userConfigurable: false,
                value: false
            },
            thumbsDownEmail: {
                devConfigurable: true,
                label: "Email Notification for Thumbs down",
                type: "toggle",
                userConfigurable: false,
                value: false
            },
            preferredSourceType: {
                devConfigurable: true,
                label: "Preferred Source Type (web, qna, pdf, etc. - ensure half of the sources are from this type)",
                type: "text",
                userConfigurable: false,
                value: "web"
            },
            enableIngest: {
                devConfigurable: true,
                label: "Enable ingest to run for this bot",
                type: "toggle",
                userConfigurable: false,
                value: true
            }
        }
    })
    .after((conf, options) => {
        return SystemConfig.fromObject(conf);
    });

SystemConfigFactory.newConfig = function (data) {
    const defaultData = {
        entityId: 'systemConfig',

        organizationId: 'UMD',
        config: {
            database: {
                name: {
                    devConfigurable: false,
                    label: "Database Name",
                    type: "string",
                    userConfigurable: false,
                    value: "apphosting"
                },
                host: {
                    devConfigurable: true,
                    type: "text",
                    userConfigurable: false,
                    value: "rds-aurora-chatbot.it-dev-apphosting.aws.umd.edu"
                },
                port: {
                    devConfigurable: false,
                    type: "text",
                    userConfigurable: false,
                    value: "5432"
                },
                user: {
                    devConfigurable: false,
                    type: "text",
                    userConfigurable: false,
                    value: "aibot"
                },
                schema: {
                    devConfigurable: true,
                    label: "Schema",
                    type: "text",
                    value: ""
                },
                collection: {
                    devConfigurable: true,
                    label: "Collection",
                    type: "text",
                    userConfigurable: false,
                    value: ""
                },
                type: {
                    devConfigurable: false,
                    label: "Collection",
                    type: "string",
                    userConfigurable: false,
                    value: "postgres"
                }
            },
            intro: {
                greeting: {
                    devConfigurable: true,
                    label: "Greeting Message",
                    type: "textarea",
                    userConfigurable: true,
                    value: "",
                    sortOrder: "2"
                },
                followup1: {
                    devConfigurable: true,
                    label: "Follow-up Message 1",
                    type: "textarea",
                    userConfigurable: true,
                    value: "",
                    sortOrder: "3"
                },
                followup2: {
                    devConfigurable: true,
                    label: "Follow-up Message 2",
                    type: "textarea",
                    userConfigurable: true,
                    value: "",
                    sortOrder: "4"
                },
                name: {
                    devConfigurable: true,
                    label: "Agent Name",
                    type: "textarea",
                    userConfigurable: true,
                    value: "",
                    sortOrder: "1"
                }
            },
            llm: {
                config: {
                    apiBase: {
                        devConfigurable: true,
                        label: "API Base (azure - https://umd-dit-gpt.openai.azure.com/ (GPT 3.5) or https://umd-dit-gpt-useast2.openai.azure.com/ (GPT 4))",
                        type: "text",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "7"
                    },
                    apiType: {
                        devConfigurable: true,
                        label: "API Type",
                        type: "text",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "8"
                    },
                    apiVersion: {
                        devConfigurable: true,
                        label: "API Version",
                        type: "text",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "9"
                    },
                    deploymentName: {
                        devConfigurable: true,
                        label: "Deployment Name (umd-gpt-35-turbo, gpt-4-0613-older - LLM to use to ask the questions)",
                        type: "text",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "2"
                    },
                    distanceCeiling: {
                        devConfigurable: true,
                        label: "Distance Ceiling (exclude any sources higher than this distance number)",
                        type: "number",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "5"
                    },
                    distanceDelta: {
                        devConfigurable: true,
                        label: "Distance Delta (exclude non-prefered sources if further than delta from best preferred source)",
                        type: "number",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "4"
                    },
                    docsToRetrieve: {
                        devConfigurable: true,
                        label: "Number of Docs to Retrieve",
                        type: "number",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "3"
                    },
                    embeddingDeploymentName: {
                        devConfigurable: true,
                        label: "Embedding Deployment Name (text-embedding-ada-002 - model to use for vector embeddings)",
                        type: "text",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "6"
                    },
                    maxMemory: {
                        devConfigurable: true,
                        label: "Max Memory",
                        type: "number",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "10"
                    },
                    maxTokens: {
                        devConfigurable: true,
                        label: "Max Tokens",
                        type: "number",
                        userConfigurable: false,
                        value: "",
                        sortOrder: "11"
                    },
                    temperature: {
                        devConfigurable: true,
                        label: "Temperature",
                        type: "number",
                        userConfigurable: false,
                        value: "0.9",
                        sortOrder: "1"
                    },
                    
                },
                GPT35: {
                    deploymentName: "umd-gpt-35-turbo",
                    docsToRetrieve: 4,
                    temperature: 0.7,
                    distanceDelta: 0.2,
                    embeddingDeploymentName: "text-embedding-ada-002",
                    apiBase: "https://umd-gpt-dpt.openai.azure.com/",
                    apiType: "azure",
                    apiVersion: "2023-03-15-preview"
                },
                GPT4: {
                    deploymentName: "umd-gpt-4-main",
                    docsToRetrieve: 5,
                    temperature: 0.6,
                    distanceDelta: 0.15,
                    embeddingDeploymentName: "text-embedding-da-vinci-003",
                    apiBase: "https://api.open.com/v1/",
                    apiType: "openai",
                    apiVersion: "2024-01-01-preview"
                }
            },
            prompt: {
                text: {
                    devConfigurable: true,
                    label: "Text",
                    type: "textarea",
                    userConfigurable: false,
                    value: "Dynamic question and answer based on context and history.",
                },
                normalPrompt: {
                    devConfigurable: true,
                    label: "Standard Prompt",
                    type: "textarea",
                    userConfigurable: false,
                    value: "Standard interaction initiation.",
                },
                quietPrompt: {
                    devConfigurable: true,
                    label: "Quiet Mode Prompt",
                    type: "textarea",
                    userConfigurable: false,
                    value: "Reduced interaction mode.",
                },
                quietModeActivated: {
                    devConfigurable: true,
                    label: "Quiet Mode Activation",
                    type: "toggle",
                    userConfigurable: true,
                    value: false,
                }
            },
            general: {
                logQuestions: {
                    devConfigurable: true,
                    label: "Log Questions to Console",
                    type: "toggle",
                    userConfigurable: false,
                    value: false
                },
                thumbsDownEmail: {
                    devConfigurable: true,
                    label: "Email Notification for Thumbs down",
                    type: "toggle",
                    userConfigurable: false,
                    value: false
                },
                preferredSourceType: {
                    devConfigurable: true,
                    label: "Preferred Source Type (web, qna, pdf, etc. - ensure half of the sources are from this type)",
                    type: "text",
                    userConfigurable: false,
                    value: "web"
                },
                enableIngest: {
                    devConfigurable: true,
                    label: "Enable ingest to run for this bot",
                    type: "toggle",
                    userConfigurable: false,
                    value: true
                }
        }
    }
}
    const mergedData = { ...defaultData, ...data };

    return this.build(mergedData);
    // const mergedData = {...this.build(), ...data};
    // return SystemConfig.fromObject(mergedData);
};

export default SystemConfigFactory;


// import {Factory} from 'rosie';
// import {SystemConfig} from "../models/SystemConfig.js";

// const SystemConfigFactory = new Factory()
//     .attr('entityType', 'systemConfig')
//     .attr('organizationId', 'TESTORG')
//     .attr("config", {
//         database:{},
//         llm:{},
//         intro:{},
//         prompt:{}
//     })
//     .attr('version', 1)
//     .attr('timestamp', new Date().toISOString())
//     .after((conf, options) => {
//         return SystemConfig.fromObject(conf);
//     });

// SystemConfigFactory.newConfig = function (data) {
//     const defaultData = {
//         organizationId: 'UMD',
//         config: {
//             database: {
//                 type: { value: "postgres" },
//                 name: { value: "apphosting" },
//                 host: { value: "rds-aurora-chatbot.it-dev-apphosting.aws.umd.edu" },
//                 port: { value: "5432" },
//                 user: { value: "aibot" }
//             },
//             llm: {
//                 "config":{

//                 },
//                 "GPT-3.5": {
//                     temperature:  0.7 ,
//                     deploymentName: "umd-gpt-35-turbo",
//                     numDocumentsToRetrieve:  4 ,
//                     distanceDelta:  0.2 ,
//                     embeddingDeploymentName: "text-embedding-ada-002" ,
//                     apiBase: "https://umd-gpt-dpt.openai.azure.com/",
//                     apiType: "azure",
//                     apiVersion: "2023-03-15-preview"
//                 },
//                 "GPT-4": {
//                     temperature:  0.6,
//                     deploymentName:  "umd-gpt-4-main",
//                     numDocumentsToRetrieve:  5,
//                     distanceDelta:  0.15,
//                     embeddingDeploymentName: "text-embedding-da-vinci-003",
//                     apiBase: "https://api.open.com/v1/",
//                     apiType: "openai",
//                     apiVersion: "2024-01-01-preview"
//                 }
//             },
//             prompt: {
//                 text: {value: '"You are an expert executive AI assistant at a university that helps Axel find information. You always return an answer from a combination of the Content (delimeted by <ctx></ctx>), the Previous Content in chat history (delimeted by <hist></hist>), and your knowledge. If there is any chat history and you are unsure about the current question then combine the current question with the Human and AI questions and answers in the chat history. If a related URL is included in the source then provide it. If Axel asks you a question you don\'t know the answer, ask Axel to rephrase the question or tell him to add source material in his bot google drive at https://drive.google.com/drive/u/1/folders/1exAzx3ExMzRJ2G_JP9yp2WBM6rJzB3FV. Keep your greeting brief. Don\'t talk about the context or history in the answer. After the answer include the Source or Previous Source that most contibuted to your answer delimimted by <src></src>. If you did not know the answer include <src>none</src> after the answer. \n' +
//                         '------ \n' +
//                         '<ctx> \n' +
//                         '{summaries} \n' +
//                         '</ctx>\n' +
//                         ' ------ \n' +
//                         '<hist> \n' +
//                         '{chat_history} \n' +
//                         '</hist>\n' +
//                         ' ------ \n' +
//                         'Question: {question} \n' +
//                         'Your Answer:"'}
//             },
//             intro: {
//                 name: {value: "Default" },
//                 greeting: {value: "Hello Axel! What can I help you with today?"},
//                 followup1: {value: "..." },
//                 followup2: {value: "..." }
//             },
//             general: {
//                 preferredSourceType: {value: ''}
//             }
//         },
//         version: 1,
//     };

//     const mergedData = {...defaultData, ...data};

//     return this.build(mergedData);
// };

// export default SystemConfigFactory;
