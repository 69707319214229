// Lang Chain Short Vector Embedding model class (document substring, only some cmetadata)
export class ShortEmbedding {
  constructor(data) {
    this.entitytype = "shortEmbedding";
    this.uuid = data.uuid;
    this.sourcetype = data.sourcetype;
    this.intent = data.intent;
    this.title = data.title;
    this.docsubstring = data.docsubstring;
    this.version = data.version || 1;
  }

  static fromObject(obj) {
    return new ShortEmbedding(obj);
  }
}
