// SystemChatbotConfig model class
export class SystemChatbotConfig {
    
    constructor(data) {
        this.SKName = data.SK;
        this.entityType = "systemconfigchatbot";
        this.organizationId = data.organizationId;
        this.chatbotId=data.chatbotId;
        this.chatbotName=data.chatbotName;
        this.uuid=data.uuid;
        this.config = data.config;
        this.botType = data.botType;
        this.version = data.version;
        this.timestamp=data.timestamp;
    }

    static fromObject(obj) {
        return new SystemChatbotConfig(obj);
    }
}
