<template>
  <div id="q-app">
    <q-layout view="hHh lpR fFf">

      <q-page-container>
        <router-view></router-view>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { config } from './config/config'; // Adjust the import path as necessary

onMounted(() => {
  const envType = import.meta.env.VITE_ENV_TYPE;
  const envPrefix = envType !== 'prod' ? `${envType} - ` : '';
  document.title = `${envPrefix} Chatbot AI Admin Console`;
});

// You can use this for further functionality depending on your route
const route = useRoute();

// Managing the state of the tab
const tab = ref(null);
</script>
