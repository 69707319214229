import casRouteGuard from '../guards/casRouteGuard.js'; // Adjust path accordingly

const useCASRouteGuard = true; // Turn to false to turn off CAS for testing

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/QuestionsReviewPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'home'
      },
      {
        path: 'botConfig',
        component: () => import('pages/BotConfig.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'botConfig'
      },
      {
        path: 'developerConfig',
        component: () => import('pages/DeveloperConfig.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'developerConfig'
      },
      {
        path: 'embeddingsPage',
        component: () => import('pages/EmbeddingsPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'embeddingsPage'
      },
      {
        path: 'provisioning',
        component: () => import('pages/ProvisioningPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'provisioning'
      },
      {
        path: 'provisioningRequest',
        component: () => import('pages/ProvisioningRequestPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'provisioningRequest'
      },
      {
        path: 'systemConfig',
        component: () => import('pages/SystemConfig.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'systemConfig'
      },
      {
        path: 'connectorSystemConfig',
        component: () => import('pages/ConnectorSystemConfig.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'connectorSystemConfig'
      },
      {
        path: 'developerTools',
        component: () => import('pages/DeveloperTools.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'developerTools'
      },
      {
        path: 'dynamoTools',
        component: () => import('pages/DynamoTools.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'dynamoToolsTools'
      },
      {
        path: 'analyticsBot',
        component: () => import('pages/AnalyticsBotPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'analyticsBot'
      },
      {
        path: 'analyticsTechnical',
        component: () => import('pages/AnalyticsTechnicalPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'analyticsTechnical'
      },
      {
        path: 'questionReview',
        component: () => import('pages/QuestionsReviewPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'questionReview'
      },
      {
        path: 'qna',
        component: () => import('pages/QnAPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'qna'
      },
      {
        path: 'adminUsers',
        component: () => import('pages/AdminUsers.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'adminUsers'
      },
      {
        path: 'adminChatbots',
        component: () => import('pages/AdminChatbots.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'adminChatbots'
      },
      {
        path: 'chatbotOverview',
        component: () => import('pages/ChatbotOverview.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'chatbotOverview'
      },
      {
        path: 'sampleQuestions',
        component: () => import('pages/SampleQuestionsPage.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'sampleQuestions'
      },
      {
        path: 'chatbotPrereqs/:chatbotId?',
        component: () => import('pages/ChatbotPrereqs.vue'),
        beforeEnter: useCASRouteGuard ? casRouteGuard : undefined,
        name: 'chatbotPrereqs'
      }
    ]
  },
  {
    path: '/userNotInSystem',
    component: () => import('pages/UserNotInSystem.vue'),
    name: 'userNotInSystem'
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
];

export default routes;
