import {Factory} from 'rosie';
import {ConnectorChatbotConfig} from "../models/ConnectorChatbotConfig.js";

const ConnectorChatbotConfigFactory = new Factory()
    .attr('entityType', 'connectorchatbotconfig')
    .attr('organizationId', 'TESTORG')
    .attr("config", {})
    .attr('version', 1)
    .attr('timestamp', new Date().toISOString())
    .after((conf, options) => {
        return ConnectorChatbotConfig.fromObject(conf);
    });

ConnectorChatbotConfigFactory.newConfig = function (data) {
    const defaultData = {
        organizationId: 'UMD',
        version: 1,
        config: {}
    };

    const mergedData = {...defaultData, ...data};

    return this.build(mergedData);
};

export default ConnectorChatbotConfigFactory;
