export class SessionState {
  constructor(data) {
    this.entityType = "sessionState";
    this.sessionId = data.sessionId.toUpperCase();
    this.entityId = data.entityId.toUpperCase();
    this.stateData = data.stateData;
    this.timestamp = data.timestamp;
    this.version = data.version || 1;
  }

  static fromObject(obj) {
    return new SessionState(obj);
  }
}
