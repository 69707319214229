// ConnectorConfig model class
export class ConnectorConfig {
    constructor(data) {
        this.entityType = "connectorSystemConfig";
        this.organizationId = data.organizationId.toUpperCase();
        this.connectorName=data.connectorName;
        this.connectorId=data.connectorId;
        this.displayName=data.displayName;
        this.description=data.description;
        this.configUI = data.configUI;
        this.version = data.version || 1;
        this.timestamp=data.timestamp;
    }

    static fromObject(obj) {
        return new ConnectorConfig(obj);
    }
}
