import axios from "axios";
import userApi from "../api/userApi.js";
import { config } from "../config/config.js";

const serviceURL = config.serviceURL;
const casLoginBaseURL = config.casLoginBaseURL;
let casJwtIssuingLambdaURL = config.casJwtIssuingLambdaURL;
const encodedServiceURL = encodeURIComponent(serviceURL);
const casLoginURL = `${casLoginBaseURL}?service=${encodedServiceURL}`;


export default async function casRouteGuard(to, from, next) {
  if (to.meta.skipAuthGuard) {
    console.log("CAS route guard skipped.");
    next();
    return;
  }

  console.log("CAS route guard activated.");

  // If we already have a JWT in local storage, check if it's expired / valid
  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    const isJwtValid = await routeGuardUtils.validateAndProcessJwt(jwt, next);
    if (!isJwtValid) {
      routeGuardUtils.redirectToCASLogin(to);
      return next(false);
    }
    console.log("Found valid JWT in local storage. Continue to process route.");
    // After successful validation, retrieve the original hash and navigate to it
    const originalHash = localStorage.getItem('originalHash');
    if (originalHash) {
      localStorage.removeItem('originalHash'); // Clean up after use
      next({ path: originalHash, replace: true, meta: { skipAuthGuard: true } });
    }
    return next();
  }

  // If there's no JWT in local storage, check if there's a ticket in the URL
  // and process it to get the JWT
  const ticket = new URLSearchParams(window.location.search).get("ticket");
  console.log('Got ticket from CAS: ', ticket)
  if (ticket) {
    const jwtFromTicket = await routeGuardUtils.processTicket(ticket);
    if (!jwtFromTicket) {
      routeGuardUtils.redirectToCASLogin(to);
      return next(false);
    }
    await routeGuardUtils.validateAndProcessJwt(jwtFromTicket, next);
    const originalHash = localStorage.getItem('originalHash');
    if (originalHash) {
      localStorage.removeItem('originalHash'); // Clean up after use
      next({ path: originalHash, replace: true, meta: { skipAuthGuard: true } });
    }
    return next();
  }

  routeGuardUtils.redirectToCASLogin(to);
  return next(false);
}

// These are exported for testing purposes so we can stub them in sinon
export const routeGuardUtils = {


  async validateAndProcessJwt(jwt, next) {
    if (this.isJwtTokenExpired(jwt)) {
      console.log("JWT is expired.");
      localStorage.clear();
      return false;
    }

    console.log("JWT is not expired. Continue to process route.");

    try {
      // Set JWT so API can make a call to get the current user
      localStorage.setItem("jwt", jwt);
      console.log("Call getCurrentUser");
      const user = await userApi.getCurrentUser();
      console.log("Got current user:", user);
      console.log("Store current user in local storage.");
      localStorage.setItem("user", JSON.stringify(user));
      return true;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found in system since we got a 404. Send to userNotInSystem page.");
        localStorage.clear();
        return next('/userNotInSystem');
      }
      console.log("Error fetching current user:", error);
      localStorage.clear();
      return false;
    }
  },

  async processTicket(ticket) {
    console.log("Ticket found in URL:", ticket);
    try {
      const jwt = await this.getJwtTokenWithCasTicket(ticket);
      return jwt ? jwt : null;
    } catch (error) {
      console.error("Error processing ticket:", error);
      return null;
    }
  },

  async getJwtTokenWithCasTicket(ticket) {
    let queryParams = `?serviceUrl=${encodeURIComponent(serviceURL)}&ticket=${encodeURIComponent(ticket)}`;
    console.log(`Get JWT Token from JWT Issuing Lambda with query params: ${queryParams}`);
    try {
      const newUrl = window.location.pathname + window.location.search.replace(`ticket=${ticket}`, '');
      window.history.replaceState({}, '', newUrl);
      console.log(`Call JWT Lambda axios.get: ${casJwtIssuingLambdaURL}${queryParams}`)
      const response = await axios.get(`${casJwtIssuingLambdaURL}${queryParams}`);
      console.log("JWT Lambda Response is", response);
      return response.data.token;
    } catch (error) {
      console.error("Error getting JWT from Lambda using CAS ticket:", error);
      throw error;
    }
  },

  redirectToCASLogin(to) {
    let redirectCount = parseInt(localStorage.getItem("redirectedToCas"), 10);

    // Prevent infinite redirect loop
    if (redirectCount >= 3) {
      console.error("Already redirected to CAS 3 times. Can't redirect again. Refresh the page to try to login again.");
      localStorage.clear(); // Optional: You might want to keep the count for logging or other purposes
      return;
    }

    redirectCount = isNaN(redirectCount) ? 1 : redirectCount + 1;
    localStorage.setItem("redirectedToCas", redirectCount.toString());
    const originalHash = to.fullPath;
    localStorage.setItem('originalHash', originalHash);
    window.location.href = casLoginURL;
  },


  decodeJWT(token) {
    const tokenParts = token.split(".");
    const payload = tokenParts[1];
    return JSON.parse(atob(payload.replace(/-/g, "+").replace(/_/g, "/")));
  },

  isJwtTokenExpired(token) {
    const exp = this.decodeJWT(token).exp;
    return !exp || Math.floor(Date.now() / 1000) >= exp;
  }
};

