import { Factory } from 'rosie';
import { SessionState } from '../models/SessionState.js';

const SessionStateFactory = new Factory()
  .sequence('entityId', (i) => `STATE${i}`) // Auto-incrementing STATE IDs
  .attr('organizationId', 'org1')
  .attr('entityType', 'sessionState')
  .attr('sessionId', 'TESTSESSION')
  .attr('stateData', 'defaultStateData')
  .attr('timestamp', () => new Date().toISOString())
  .attr('version', 1)
  .after((sessionState, options) => {
    return SessionState.fromObject(sessionState);
  });

SessionStateFactory.newSessionState = function(data) {
  const defaultData = {
    organizationId: 'UMD',
    stateData: '',
    timestamp: new Date().toISOString()
  };

  const mergedData = { ...defaultData, ...data };
  return this.build(mergedData);
};

export default SessionStateFactory;
