import { Factory } from 'rosie';
import { Syllabus } from '../models/SyllabusInfo.js'; // Updated import path
import { v4 as uuidv4 } from 'uuid';

const SyllabusFactory = new Factory()
  .attr('chatbotId', () => uuidv4()) // Generates a unique UUID for each syllabus
  .attr('course_name', 'Sample Course') // Default course name; adjust as needed
  .attr('course_code', 'CMSC216') // Default course code; adjust as needed
  .attr('instructor_names', ['John Doe', 'Jane Smith']) // Default instructor names; adjust as needed
  .attr('office_hours', ['Mon 10-11am', 'Wed 2-3pm']) // Default office hours; adjust as needed
  .attr('comm_mode', ['Email', 'Phone']) // Default communication modes; adjust as needed
  .attr('course_material', ['Book A', 'Book B', 'Book C']) // Default course materials; adjust as needed
  .attr('course_objective', 'To learn the basics of programming') // Default course objective; adjust as needed
  .attr('course_desc', 'An introductory course in computer science') // Default course description; adjust as needed
  .attr('topics', ['Variables', 'Functions', 'Loops']) // Default topics; adjust as needed
  .attr('test_questions', []) // New questions column, defaulting to an empty list
  .attr('test_answers', []) // New answers column, defaulting to an empty list
  .attr('is_questions_answered', false) // New boolean column, defaulting to false
  .attr('timestamp', () => new Date().toISOString()) // Current timestamp in ISO format
  .after((syllabus, options) => {
    return Syllabus.fromObject(syllabus);
  });

SyllabusFactory.newSyllabus = function(data) {
  const defaultData = {
    course_name: '',
    course_code: '',
    instructor_names: [],
    office_hours: [],
    comm_mode: [],
    course_material: [],
    course_objective: '',
    course_desc: '',
    topics: [],
    test_questions: [],
    test_answers: [],
    is_questions_answered: false,
    timestamp: new Date().toISOString()
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default SyllabusFactory;
