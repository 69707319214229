import { Factory } from 'rosie';
import { Chatbot } from '../models/Chatbot.js';
import UserFactory from "./userFactory.js";

const ChatbotFactory = new Factory()
  .sequence('entityId', (i) => `BOT${i}`) // Auto-incrementing BOT IDs
  .attr('botType', "departmental")
  .attr('entityType', 'chatbot')
  .attr('organizationId', 'TESTORG')
  .attr('config', {
    googleDrive: {
      botFolderId: 'defaultBotFolderId'
    },
    postgres: {
      name: 'apphosting',
      host: '',
      port: '5432',
      user: 'aibot',
      schema: '',
      collection: ''
    },
    intro: {
      greeting: '',
      followup1: '',
      followup2: '',
      name: "UMD Virtual Agent"
    },
    prompt: {
      text: '',
      normalPrompt: '',
      quietPrompt:  '',
      quietModeActivated: false,
    },
    llm: {
      deploymentName: 'umd-gpt-35-turbo',
      docsToRetrieve: 4,
      temperature: 0.7,
      distanceDelta: 0.05,
      distanceCeiling: 0.5,
      embeddingDeploymentName: 'text-embedding-ada-002',
      apiBase: 'https://umd-dit-gpt.openai.azure.com/',
      apiVersion: '2023-03-15-preview',
      apiType: 'azure',
      maxTokens: 3000,
      maxMemory: 2,
    },
    webScraper: {
      urlToScrape: '',
      urlToNotScrape: '',
      enableAiIntentGen: false,
      enableIngest: true,
      forceScrape: false,
      forceClearAndReingest: false,
      enableSpellChecking: false,
      preferredSourceType: '',
      scrapeTime: '6h 35m',
      lastScrape: '19769d 10h 27m'
    },
    genConfig: {
      logQuestions: true,
      thumbsDownEmail: true
    }
  })
  .attr('version', 1)
  .after((chatbot, options) => {
    return Chatbot.fromObject(chatbot);
  });

ChatbotFactory.newChatbot = function(data) {
  const defaultData = {
    entityId: '',
    name: '',
    botType: 'departmental',
    entityType: 'chatbot',
    organizationId: 'UMD',
    config: {
      googleDrive: {
        botFolderId: ''
      },
      postgres: {
        name: 'apphosting',
        host: '',
        port: '5432',
        user: 'aibot',
        schema: '',
        collection: ''
      },
      intro: {
        greeting: '',
        followup1: '',
        followup2: '',
        name: '',
      },
      prompt: {
        text: '',
        normalPrompt: '',
        quietPrompt:  '',
        quietModeActivated: false,
      },
      llm: {
        deploymentName: 'umd-gpt-35-turbo',
        docsToRetrieve: 4,
        temperature: 0.7,
        distanceDelta: 0.05,
        distanceCeiling: 0.5,
        embeddingDeploymentName: 'text-embedding-ada-002',
        apiBase: 'https://umd-dit-gpt.openai.azure.com/',
        apiVersion: '2023-03-15-preview',
        apiType: 'azure',
        maxTokens: 3000,
        maxMemory: 2,
      },
      webScraper: {
        urlToScrape: '',
        urlToNotScrape: '',
        enableAiIntentGen: false,
        enableIngest: true,
        forceScrape: false,
        forceClearAndReingest: false,
        enableSpellChecking: false,
        preferredSourceType: '',
        scrapeTime: '6h 35m',
        lastScrape: '19769d 10h 27m'
      },
      genConfig: {
        logQuestions: true,
        thumbsDownEmail:true
    }
  }
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default ChatbotFactory;
