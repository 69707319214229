
export const baseQuestionSchema = {
  type: "object",
  properties: {
    entityType: { type: "string", const: "question" },
    organizationId: { type: "string" },
    entityId: { type: "string", format: "uuid" },
    knownOrUnknown: { type: "string", enum: ["unknown", "known"] },
    thumbs: { type: "string", enum: ["none", "up", "down"] },
    feedback: { type: "string" },
    prompt: {type: "string"},
    historyText: {type: "string"},
    reviewQuestionWithDev: { type: "boolean" },
    developerProvidedComment: { type: "boolean" },
    developerNote: { type: ["string"] },
    noteFromDev: { type: ["string"] },
    ignoreAsTestQuestion: { type: "boolean" },
    llmModel: { type: "string" },
    reviewedOnDate: {
      oneOf: [
        { type: "string", format: "date" },  // Valid date string
        { type: "string", maxLength: 0 },    // Empty string
      ]
    },
    question: { type: "string" },
    response: { type: "string" },
    intentsReportedByLlm: {
      type: "array",
      items: { type: "object", properties: { intent: { type: "string" } }, required: ["intent"] }
    },
    intentUsed: { type: "string" },
    tokensTotal: { type: "integer" },
    tokenCountDetails: {
      type: "object",
      properties: {
        template: { type: "integer" },
        context: { type: "integer" },
        hist_text: { type: "integer" },
        hist_ctx: { type: "integer" },
        question: { type: "integer" },
        answer: { type: "integer" }
      },
      required: ["template", "context", "hist_text", "hist_ctx", "question", "answer"]
    },
    sources: {
      type: "array",
      items: {
        type: "object",
        properties: {
          tokens: { type: "integer" },
          distance: { type: "number" },
          type: { type: "string" },
          intent: { type: "string" },
          content: { type: "string" },
          title: { type: "string" }
        },
        required: ["tokens", "distance", "type", "intent", "content", "title"]
      }
    },
    previousSources: {
      type: "array",
      items: {
        type: "object",
        properties: {
          tokens: { type: "integer" },
          distance: { type: "number" },
          type: { type: "string" },
          intent: { type: "string" },
          content: { type: "string" },
          title: { type: "string" }
        },
        required: ["tokens", "distance", "type", "intent", "content", "title"]
      }
    },
    sessionId: { type: "string", format: "uuid" },
    userId: { type: ["string", "null"] },
    timestamp: { type: "string", format: "date-time" }
  },
  required: [
    "entityType", "entityId", "knownOrUnknown", "thumbs", "reviewQuestionWithDev",
    "ignoreAsTestQuestion", "llmModel", "reviewedOnDate", "organizationId","question", "response", "intentsReportedByLlm", "intentUsed",
    "tokensTotal", "tokenCountDetails", "sources", "sessionId", "timestamp"
  ],
  additionalProperties: true
};

/**
 * @swagger
 * components:
 *   schemas:
 *     CreateQuestion:
 *       type: object
 *       required:
 *         - entityType
 *         - knownOrUnknown
 *         - thumbs
 *         - reviewQuestionWithDev
 *         - ignoreAsTestQuestion
 *         - llmModel
 *         - reviewedOnDate
 *         - organizationId
 *         - question
 *         - response
 *         - intentsReportedByLlm
 *         - intentUsed
 *         - tokensTotal
 *         - tokenCountDetails
 *         - sources
 *         - sessionId
 *         - uuid
 *         - timestamp
 *       properties:
 *         entityType:
 *           type: string
 *           const: question
 *         entityId:
 *           type: string
 *           format: uuid
 *         knownOrUnknown:
 *           type: string
 *           enum: [unknown, known]
 *         thumbs:
 *           type: string
 *           enum: [none, up, down]
 *         reviewQuestionWithDev:
 *           type: boolean
 *         developerNote:
 *           type: string
 *           nullable: true
 *         ignoreAsTestQuestion:
 *           type: boolean
 *         llmModel:
 *           type: string
 *         reviewedOnDate:
 *           type: string
 *           nullable: true
 *           format: date
 *         organizationId:
 *           type: string
 *         question:
 *           type: string
 *         response:
 *           type: string
 *         intentsReportedByLlm:
 *           type: array
 *           items:
 *             type: object
 *             properties:
 *               intent:
 *                 type: string
 *             required:
 *               - intent
 *         intentUsed:
 *           type: string
 *         tokensTotal:
 *           type: integer
 *         tokenCountDetails:
 *           type: object
 *           properties:
 *             template:
 *               type: integer
 *             context:
 *               type: integer
 *             hist_text:
 *               type: integer
 *             hist_ctx:
 *               type: integer
 *             question:
 *               type: integer
 *             answer:
 *               type: integer
 *           required: [template, context, hist_text, hist_ctx, question, answer]
 *         sources:
 *           type: array
 *           items:
 *             type: object
 *             properties:
 *               tokens:
 *                 type: integer
 *               distance:
 *                 type: number
 *               type:
 *                 type: string
 *               intent:
 *                 type: string
 *               content:
 *                 type: string
 *               first_50_chars:
 *                 type: string
 *             required: [tokens, distance, type, intent, content, first_50_chars]
 *         sessionId:
 *           type: string
 *           format: uuid
 *         timestamp:
 *           type: string
 *           format: date-time
 */
export const createQuestionSchema = {
  ...baseQuestionSchema,
  required: baseQuestionSchema.required.filter(key => key !== 'entityId')
};


/**
 * @swagger
 * components:
 *   schemas:
 *     UpdateQuestion:
 *       allOf:
 *         - $ref: '#/components/schemas/CreateQuestion'
 *         - type: object
 *           required:
 *             - entityId
 */
export const updateQuestionSchema = {
  ...baseQuestionSchema
};

