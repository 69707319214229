 export const baseOrganizationSchema = {
  type: "object",
  properties: {
    entityType: { type: "string", const: "organization" },
    entityId: { type: "string" },
    name: { type: "string" },
    version: { type: "integer", minimum: 1 }
  },
  required: [ "entityId", "name", "entityType", "version"],
  additionalProperties: false
};

export const createOrganizationSchema = {
  ...baseOrganizationSchema,
  required: baseOrganizationSchema.required.filter(key => key !== 'entityId')
};


export const updateOrganizationSchema = {
  ...baseOrganizationSchema
};
