import { User,Chatbot, SystemChatbotConfig } from "umdaicommon";
import { chatbotState } from '../state/chatbotState.js';

// The user is centrally set in the local storage on CAS login
// The dev mode selector will also overwrite the user to change users for testing
// The API methods should send this user to the backend
export function getUser() {
  let currentUser = localStorage.getItem("user");
  if (currentUser) {
    currentUser = User.fromObject(JSON.parse(currentUser));
  }
  return currentUser;
}

// Ensures we always get the most recent chatbot and persist it across page refrese
export function getSelectedChatbot() {
  let selectedChatbot = localStorage.getItem("selectedChatbot");
  if (selectedChatbot) {
    selectedChatbot = SystemChatbotConfig.fromObject(JSON.parse(selectedChatbot));
  }
  return selectedChatbot;
}

