import { Factory } from 'rosie';
import { Question } from '../models/Question.js'; // Update the import path
import { v4 as uuidv4 } from 'uuid';

const QuestionFactory = new Factory()
  .attr('entityType', 'question')
  .attr('entityId', () => uuidv4()) // Generates a unique UUID for each question
  .attr('knownOrUnknown', 'unknown') // Default value; adjust as needed
  .attr('thumbs', 'none') // Default value; can be 'up' or 'down'
  .attr('feedback', '') // Default value; adjust as needed
  .attr('prompt', '')
  .attr('reviewQuestionWithDev', false) // Default value; adjust as needed
  .attr('developerProvidedComment', false)
  .attr('developerNote', "") // Default value; adjust as needed
  .attr('noteFromDev', "")
  .attr('ignoreAsTestQuestion', false) // Default value; adjust as needed
  .attr('llmModel', 'ChatGPT 3.5') // Default model; adjust as needed
  .attr('reviewedOnDate', "") // Default value; adjust as needed
  .attr('organizationId', 'TESTORG') // Default organization ID; adjust as needed
  .attr('question', 'What is the weather like today?') // Default question; adjust as needed
  .attr('response', 'It is sunny today.') // Default response; adjust as needed
  .attr('intentsReportedByLlm', () => [{ intent: 'weather_check' }]) // Default intents; adjust as needed
  .attr('intentUsed', 'weather_check') // Default intent; adjust as needed
  .attr('tokensTotal', 237) // Default token count; adjust as needed
  .attr('tokenCountDetails', () => ({
    template: 198,
    context: 373,
    hist_text: 1,
    hist_ctx: 0,
    question: 1,
    answer: 4
  }))
  .attr('sources', () => [
    {
      tokens: 234,
      distance: 0.1691,
      type: 'web',
      intent: 'w_weather',
      content: 'Weather forecast details',
      title: 'Spreadsheet'
    }
  ])
  .attr('previousSources', () => [
    {
      tokens: 234,
      distance: 0.1691,
      type: 'web',
      intent: 'w_weather',
      content: 'Weather forecast details',
      title: 'Spreadsheet'
    }
  ])
  .attr('sessionId', () => uuidv4()) // Generates a unique session ID
  .attr('userId', "")
  .attr('timestamp', () => new Date().toISOString()) // Current timestamp in ISO format
  .after((question, options) => {
    return Question.fromObject(question);
  });



export default QuestionFactory;
