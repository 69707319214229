import { Factory } from 'rosie';

const AnalyticsFactory = new Factory()
  .sequence('Id', (i) => `metric${i}`)
  .attr('Label', ['Label'], (label) => label || 'databaseDataStorageKB')
  .attr('Timestamps', () => ["2023-12-21T11:52:16.310Z"])
  .attr('Values', () => [18.278184044949718])
  .attr('StatusCode', 'Complete');

AnalyticsFactory.getMockMetrics = function() {
  const metricNames = [
    "databaseDataStorageKB",
    "vectorEmbeddingsInDatabaseCount",
    "vectorEmbeddingGenerateCallCount",
    "lambdaExecutionOccurrence",
    "tokenQuestionCount",
    "audioDataTransferSeconds",
    "anyQuestionsAskedOccurrence",
    "unknownQuestionsAskedOccurrence",
    "thumbsDownQuestionsOccurrence",
    "thumbsUpQuestionsOccurrence"
  ];

  return metricNames.map((name, index) =>
    AnalyticsFactory.build({ Label: name, Id: `metric${index}` })
  );
};

export default AnalyticsFactory;
