export const baseTopicSentimentSchema = {
    type: "object",
    properties: {
      organizationId: { type: "string" },
      courseName: {type: "string"},
      chatbotUuid: {type: "string"},
      professorEmail: {type: "string"},
      datetime: { type: "string", format: "date-time" },
      topic_count: {
        type: "object",
        additionalProperties: { type: "integer" }
      },
      sentiment_count: {
        type: "object",
        properties: {
          positive: { type: "integer" },
          neutral: { type: "integer" },
          negative: { type: "integer" },
          mixed: {type: "integer"}
        },
        additionalProperties: false
      },
      thumbs_count: {
        type: "object",
        additionalProperties: { type: "integer" }
      },
      list_of_negative_questions:  {  // New property for the list of questions
        type: "array",
        items: { type: "string" }
      },
      message_count_groups:{
        type: "array",
        items: {
          type: "object",
          properties: {
            "msg": { type: "string"},
            "count" : {type: "integer"}
          }
        }
      },
      thumbs_down_questions:{
        type: "array",
        items: { type: "string" }
      },
      generated_topic_graph_image: { type: "string" },
      // generated_sentiment_graph_image: { type: "string" }
    },
    required: [
      "chatbotUuid", "datetime"
    ],
    additionalProperties: false
  };
  
  export const createTopicSentimentSchema = {
    ...baseTopicSentimentSchema
  };
  
  export const updateTopicSentimentSchema = {
    ...baseTopicSentimentSchema,
    required: baseTopicSentimentSchema.required.filter(key => key !== 'chatbotId')
  };