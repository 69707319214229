import api from "./api.js";
import * as localApi from "./localApi.js";
import { User } from "umdaicommon";

const userApi = Object.create(api);

userApi.defaults.baseURL = `${api.defaults.baseURL}/`;

userApi.getUser = async function (userName) {
  try {
    console.log(`Calling getUser with path: user/${userName}`);
    const response = await userApi.get(`user/${userName}`);
    if (!response.data) {
      console.log(`No user found for userName: ${userName}`);
      return null;
    }
    const user = User.fromObject(response.data);
    console.log("User fetched:", user);
    return user;
  } catch (error) {
    console.error(`Error fetching user ${userName}:`, error);
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
}

userApi.getCurrentUser = async function () {
  console.log(`Calling getCurrentUser with path: user/current`);
  const response = await userApi.get('/user/current');
  const user = User.fromObject(response.data);
  console.log("User fetched:", user);
  return user;
}

userApi.createUser = async function (userData) {

  console.log(`Calling createUser with path: user/ and userData:`, userData);
  const response = await userApi.post(`user/`, {...userData});
  const user = User.fromObject(response.data);
  console.log("User created:", user);
  return user;
}

userApi.editUser = async function (userData) {

  console.log(`Calling editUser with path: user/${userData.userName} and userData:`, userData);
  const response = await userApi.put(`user/${userData.userName}`, {...userData});
  const user = User.fromObject(response.data);
  console.log("User edited:", user);
  return user;
}

userApi.deleteUser = async function (userName) {

  console.log(`Calling deleteUser with path: user/${userName}`);
  const response = await userApi.delete(`user/${userName}`);
  console.log("User deleted with status:", response.status);
  return response.status;
}

// Will return all users in org if you are developer admin or super admin
userApi.listUsers = async function () {

  console.log(`Calling listUsers with path: /users`);
  const response = await userApi.get(`/users`);
  const users = response.data.map(User.fromObject);
  console.log("Users fetched:", users);
  return users;
}

userApi.getCurrentUser = async function () {
  console.log(`Calling getCurrentUser with path: user/current`);
  const response = await userApi.get('/user/current');
  const user = User.fromObject(response.data);
  console.log("User fetched:", user);
  return user;
}

/**
 * This is needed because chatbotAdmins cannot see all users
 * in the system.  In Dev mode, we use this to populate the
 * dev mode select user dropdown.
 * @returns {Promise<*>}
 */
// userApi.getAllUsersDevMode = async function () {
//   console.log(`Calling getAllUsersDevMode with path: users/all-dev-mode`);
//   const response = await userApi.get('users/all-dev-mode');
//   const users = response.data.map(User.fromObject);
//   console.log("Users fetched in dev mode:", users);
//   return users;
// }

// /**
//  * Used by the dev mode selector to switch users while in dev mode
//  * @param userName
//  * @returns {Promise<User>}
//  */
// userApi.getUserDevMode = async function (userName) {
//   console.log(`Calling getUserDevMode with path: /user/${userName}/devmode`);
//   const response = await userApi.get(`/user/${userName}/devmode`);
//   const user = User.fromObject(response.data);
//   console.log("User fetched:", user);
//   return user;
// }


export default userApi;
