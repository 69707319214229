import { Factory } from 'rosie';
import { Chatbot } from '../models/Chatbot.js';
import UserFactory from "./userFactory.js";

const ChatbotDbSummaryFactory = new Factory()
  .sequence('entityId', (i) => `BOT${i}`) // Auto-incrementing BOT IDs
  .attr('totalcount', '0')
  .attr('totalsize', '0')
  .attr('webcount', '0')
  .attr('sheetcount', '0')
  .attr('doccount', '0')
  .attr('pdfcount', '0')
  .attr('slidecount', '0')
  .attr('txtcount', '0')
  .attr('qnacount', '0')
  .attr('version', 1)
  .after((chatbot, options) => {
    return Chatbot.fromObject(chatbot);
  });

  ChatbotDbSummaryFactory.newChatbot = function(data) {
  const defaultData = {
    entityId: '',
    totalcount: '0',
    totalsize: '0',
    webcount: '0',
    sheetcount: '0',
    doccount: '0',
    pdfcount: '0',
    slidecount: '0',
    txtcount: '0',
    qnacount: '0'
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default ChatbotDbSummaryFactory;
