// This allows us to mock import.meta in tests by falling back to process.env
export const config = {
  serviceURL: determineUIURL(),
  casLoginBaseURL: import.meta.env?.VITE_CAS_LOGIN_BASE_URL || process.env.VITE_CAS_LOGIN_BASE_URL,
  casLogoutURL: import.meta.env?.VITE_CAS_LOGOUT_URL || process.env.VITE_CAS_LOGOUT_URL,
  casJwtIssuingLambdaURL: import.meta.env?.VITE_CAS_JWT_ISSUING_LAMBDA_URL || process.env.VITE_CAS_JWT_ISSUING_LAMBDA_URL,
  queryLambdaURL: determineQueryURL(),
  // apiBackendLambdaBaseUrl: import.meta.env?.VITE_API_BACKEND_LAMBDA_BASE_URL || process.env.VITE_API_BACKEND_LAMBDA_BASE_URL,
  apiBackendLambdaBaseUrl: determineEnvironmentURL(),
  botUrl: determineBotURL(),
  envMode: import.meta.env?.VITE_ENV || process.env.VITE_ENV,
};

function determineEnvironmentURL() {
  const url = window.location.href; // Gets the current URL of the browser
  console.log("URL: ", url);
  if (url.includes("admin.chatbot2.dev.umd.edu")) {
    // Specific URL for the 'dev 2' environment
    return "https://n1hxidp91f.execute-api.us-east-1.amazonaws.com/";
  } else if (url.includes("admin.chatbot.dev.umd.edu")) {
    // Specific URL for the 'dev' environment
    return "https://gcu3nipl07.execute-api.us-east-1.amazonaws.com/";
  } else {
    // Default URL from environment variables if available
    return import.meta.env?.VITE_API_BACKEND_LAMBDA_BASE_URL || process.env.VITE_API_BACKEND_LAMBDA_BASE_URL;
  }
}

function determineBotURL() {
  const url = window.location.href; // Gets the current URL of the browser
  if (url.includes("admin.chatbot2.dev.umd.edu")) {
    // Specific URL for the 'dev 2' environment
    return "https://ai.chatbot2.dev.umd.edu/?botName=";
  } else if (url.includes("admin.chatbot.dev.umd.edu")) {
    // Specific URL for the 'dev' environment
    return "https://ai.chatbot.dev.umd.edu/?botName=";
  } else {
    // Default URL from environment variables if available
    return import.meta.env?.VITE_CHATBOT_URL || process.env.VITE_CHATBOT_URL;
  }
}

function determineUIURL() {
  const url = window.location.href; // Gets the current URL of the browser
  if (url.includes("admin.chatbot2.dev.umd.edu")) {
    // Specific URL for the 'dev 2' environment
    return "https://admin.chatbot2.dev.umd.edu";
  } else if (url.includes("admin.chatbot.dev.umd.edu")) {
    // Specific URL for the 'dev' environment
    return "https://admin.chatbot.dev.umd.edu";
  } else {
    // Default URL from environment variables if available
    return import.meta.env?.VITE_SERVICE_URL || process.env.VITE_SERVICE_URL;
  }
}

function determineQueryURL() {
  const url = window.location.href; // Gets the current URL of the browser
  if (url.includes("admin.chatbot2.dev.umd.edu")) {
    // Specific URL for the 'dev 2' environment
    return "https://dx2ve2wmhb.execute-api.us-east-1.amazonaws.com/";
  } else if (url.includes("admin.chatbot.dev.umd.edu")) {
    // Specific URL for the 'dev' environment
    return "https://ixcukdm275.execute-api.us-east-1.amazonaws.com/";
  } else {
    // Default URL from environment variables if available
    return import.meta.env?.VITE_API_QUERY_LAMBDA_BASE_URL || process.env.VITE_API_QUERY_LAMBDA_BASE_URL;
  }
}

