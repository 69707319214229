// Question model class
export class Question {
  constructor(data) {
    this.entityType = "question";
    this.entityId = data.entityId.toUpperCase();
    this.organizationId = data.organizationId.toUpperCase();
    // this.chatbotUuid = data.chatbotUuid.toUpperCase();
    this.knownOrUnknown = data.knownOrUnknown;
    this.thumbs = data.thumbs;
    this.prompt = data.prompt;
    this.previousSources = data.previousSources;
    this.historyText = data.historyText;
    this.feedback = data.feedback;
    this.reviewQuestionWithDev = data.reviewQuestionWithDev;
    this.developerProvidedComment = data.developerProvidedComment;
    this.developerNote = data.developerNote;
    this.noteFromDev = data.noteFromDev;
    this.ignoreAsTestQuestion = data.ignoreAsTestQuestion;
    this.llmModel = data.llmModel;
    this.reviewedOnDate = data.reviewedOnDate;
    this.question = data.question;
    this.response = data.response;
    this.intentsReportedByLlm = data.intentsReportedByLlm;
    this.intentUsed = data.intentUsed;
    this.tokensTotal = data.tokensTotal;
    this.tokenCountDetails = data.tokenCountDetails;
    this.sources = data.sources;
    this.sessionId = data.sessionId;
    this.userId = data.userId;
    this.timestamp = data.timestamp;
  }
  static fromObject(obj) {
    return new Question(obj);
  }
}
