// Syllabus model class
export class Syllabus {
  constructor(data) {
    this.entityType = "courseInfo";
    this.chatbotId = data.chatbotId;
    this.course_name = data.course_name;
    this.course_code = data.course_code;
    this.instructor_names = data.instructor_names || [];
    this.office_hours = data.office_hours || [];
    this.comm_mode = data.comm_mode || [];
    this.course_material = data.course_material || [];
    this.course_objective = data.course_objective;
    this.course_desc = data.course_desc;
    this.weeks = data.weeks || [];
    this.timestamp = data.timestamp;
    this.test_questions = data.test_questions || [];
    this.test_answers = data.test_answers || [];
    this.is_questions_answered = data.is_questions_answered || false;
  }

  static fromObject(obj) {
    return new Syllabus(obj);
  }
}
