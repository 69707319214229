

// ScriptQuestion model class
export class ScriptQuestion {
  constructor(data) {
    this.entityType  = "scriptQuestion";
    this.entityId = data.entityId.toUpperCase();
    this.version = typeof data.version === 'string' ? parseInt(data.version, 10) : data.version;
    this.organizationId = data.organizationId.toUpperCase();
    this.intentName = data.intentName;
    this.scriptQuestionText = data.scriptQuestionText;
    this.answerText = data.answerText;
    this.reviewed = data.reviewed;
    this.timestamp = data.timestamp;
  }

  static fromObject(obj) {
    return new ScriptQuestion(obj);
  }

}
