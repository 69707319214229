// SystemConfig model class
export class SystemConfig {
    constructor(data) {
        this.entityType = "systemconfig";
        this.organizationId = data.organizationId.toUpperCase();
        this.config = data.config;
        this.version = data.version || 1;
        this.timestamp=data.timestamp;
    }

    static fromObject(obj) {
        return new SystemConfig(obj);
    }
}
