import createError from "http-errors";

// User model class
export class User {
  constructor(data) {

    const roleCount = [data.roleChatbotAdmin, data.roleDeveloperAdmin, data.roleSuperAdmin].filter(Boolean).length;
    if (roleCount > 1) {
      throw createError(400, `A user cannot have more than one role.`);
    }

    this.entityType = "user";
    this.organizationId = data.organizationId.toUpperCase();
    this.userName = data.userName; // Cannot uppercase this
    this.email = data.email;
    this.roleChatbotAdmin = data.roleChatbotAdmin;
    this.roleDeveloperAdmin = data.roleDeveloperAdmin;
    this.roleSuperAdmin = data.roleSuperAdmin;
    this.version = data.version || 1;
  }

  static fromObject(obj) {
    return new User(obj);
  }

  getAccessRole() {
    if (this.roleSuperAdmin) {
      return "SuperAdmin";
    } else if (this.roleChatbotAdmin) {
      return "ChatbotAdmin";
    } else if (this.roleDeveloperAdmin) {
      return "DeveloperAdmin";
    } else {
      return "NoRole";
    }
  }

  setAccessRole(accessRole) {
    switch (accessRole) {
      case "SuperAdmin":
        this.roleSuperAdmin = true;
        this.roleChatbotAdmin = false;
        this.roleDeveloperAdmin = false;
        break;
      case "ChatbotAdmin":
        this.roleSuperAdmin = false;
        this.roleChatbotAdmin = true;
        this.roleDeveloperAdmin = false;
        break;
      case "DeveloperAdmin":
        this.roleSuperAdmin = false;
        this.roleChatbotAdmin = false;
        this.roleDeveloperAdmin = true;
        break;
      default:
        throw new Error(`Invalid access role: ${accessRole}`);
    }
  }
}
