import {Factory} from 'rosie';
import {SystemChatbotConfig} from "../models/SystemChatbotConfig.js";

const SystemChatbotConfigFactory = new Factory()
    .attr('entityType', 'systemChatbotConfig')
    .attr('chatbotId','')
    .attr('chatbotName','')
    .attr('uuid','')
    .attr('organizationId', 'TESTORG')
    .attr("config", {})
    .attr("botType", 'academic')
    .attr('version', 1)
    .attr('timestamp', new Date().toISOString())
    .after((conf, options) => {
        return SystemChatbotConfig.fromObject(conf);
    });

SystemChatbotConfigFactory.newConfig = function (data) {
    const defaultData = {
        botType: 'academic',
        organizationId: 'UMD',
        config: {
            database:{

            },
            llm: {

            },
            prompt: {

            },
            intro: {

            },
            general:{

            }
        },
        version: 1,
    };

    const mergedData = {...defaultData, ...data};

    let finalData =  this.build(mergedData);
    console.log('Chatbot config final data '+ JSON.stringify(finalData));
    return finalData;
};

export default SystemChatbotConfigFactory;
