// ConnectorConfigChatbot model class
export class ConnectorChatbotConfig {
    constructor(data) {
        this.SKName = data.SK;
        this.entityType = "connectorchatbotconfig";
        this.organizationId = data.organizationId ? data.organizationId.toUpperCase() : "";
        this.config = data.config;
        this.version = data.version || 1;
        this.timestamp=data.timestamp;
    }

    static fromObject(obj) {
        return new ConnectorChatbotConfig(obj);
    }
}
