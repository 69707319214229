export const baseSessionStateSchema = {
  type: "object",
  properties: {
    sessionId: { type: "string" },
    organizationId: { type: "string" },
    entityId: { type: "string" },
    entityType: { type: "string", const: "sessionState" },
    stateData: { type: "string" },
    timestamp: { type: "string", format: "date-time" },
    version: { type: "integer", minimum: 1 }
  },
  required: ["sessionId", "entityType", "stateData", "timestamp", "version"],
  additionalProperties: false
};

export const createSessionStateSchema = {
  ...baseSessionStateSchema
};

export const updateSessionStateSchema = {
  ...baseSessionStateSchema,
  required: [...baseSessionStateSchema.required, "entityId"]
};
