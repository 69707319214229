import { Factory } from 'rosie';
import { ScriptQuestion } from '../models/ScriptQuestion.js';
import { v4 as uuidv4 } from 'uuid';

const ScriptQuestionFactory = new Factory()
  .attr('entityId', () => uuidv4()) // Generates a unique UUID for each script question 
  .attr('version', '1') // Default version; adjust as needed
  .attr('organizationId', 'org1') // Default organization ID; adjust as needed
  .attr('intentName', '529_info') // Default intent name; adjust as needed
  .attr('scriptQuestionText', 'What is a 529 plan?') // Default script question text; adjust as needed
  .attr('answerText', 'A 529 plan is a tax-advantaged savings plan designed to encourage saving for future education costs.') // Default answer text; adjust as needed
  .attr('reviewed', false)
  .attr('timestamp', () => new Date().toISOString()) // Current timestamp in ISO format
  .after((scriptQuestion, options) => {
    return ScriptQuestion.fromObject(scriptQuestion);
  });

ScriptQuestionFactory.newScriptQuestion = function(data) {
  const defaultData = {
    organizationId: 'UMD',
    intentName: '',
    scriptQuestionText: '',
    answerText: '',
    reviewed: false
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default ScriptQuestionFactory;
