import { Factory } from 'rosie';
import {Organization} from '../models/Organization.js'; // Update the import path

const OrganizationFactory = new Factory()
  .sequence('entityId', (i) => `ORG${i}`) // Auto-incrementing organization IDs
  .attr('name', 'UMD')
  .attr('entityType', 'organization')
  .attr('version', 1)
  .after((org, options) => {
    return Organization.fromObject(org);
  });

export default OrganizationFactory;
