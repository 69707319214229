import {Factory} from 'rosie';
import {ConnectorConfig} from "../models/ConnectorConfig.js";

const ConnectorConfigFactory = new Factory()
    .attr('entityType', 'connectorSystemConfig')
    .attr('connectorName','')
    .attr('connectorId','')
    .attr('organizationId', 'TESTORG')
    .attr('description','')
    .attr("configUI", {})
    .attr('version', 1)
    .attr('timestamp', new Date().toISOString())
    .after((conf, options) => {
        return ConnectorConfig.fromObject(conf);
    });

ConnectorConfigFactory.newConfig = function (data) {
    const defaultData = {
        organizationId: 'UMD',
        connectorId:'webscraper',
        connectorName:'WebScraper',
        configUI: {

        },
        version: 1,
    };

    const mergedData = {...defaultData, ...data};

    return this.build(mergedData);
};

export default ConnectorConfigFactory;
