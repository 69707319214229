import axios from "axios";
import * as localApi from "./localApi.js";
import { config } from "../config/config.js";

const apiBackendLambdaBaseUrl = config.apiBackendLambdaBaseUrl
// console.log("apiBackendLambdaBaseUrl: ", apiBackendLambdaBaseUrl);

const api = axios.create({
  baseURL: `${apiBackendLambdaBaseUrl}`,
  timeout: 30000 // cold start is so slow
});


api.interceptors.request.use((config) => {
  //console.log(`Request headers: ${JSON.stringify(config.headers)}`);
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.request.use(async config => {
  // This will override the username and organizationId with the one we choose
  // from the dropdown in the UI. This is only used in development mode.
  // We pass these headers to the backend to override the user and org
  // and allow us to test as different users.
  try {
    let currentUser = await localApi.getUser();
    let developerModeOverride = localStorage.getItem("developerModeOveride");
    if (currentUser && developerModeOverride) {
      //config.headers = config.headers || {};
      //config.headers.common = config.headers.common || {};

      config.headers["x-dev-override-username"] = currentUser.userName;
      config.headers["x-dev-override-orgid"] = currentUser.organizationId;
    }
  } catch (error) {
    console.error("Error setting developer mode headers:", error);
  }
  // Always add the JWT to the header if it exists
  // We set this in the CAS route guard as soon as
  // we get it from the CAS server
  const jwt = localStorage.getItem("jwt");
  console.log("API call set Header JWT");
  if (jwt) {
    config.headers["Authorization"] = `Bearer ${jwt}`;
  }
  return config;
}, error => Promise.reject(error));


api.interceptors.response.use(response => {
  return response;
}, error => {

  // Need to propogate the error to the caller so they can handle it
  /*
  const errorMsg = `Error response from API: status=${error.response.status}, statusText=${error.response.statusText}${error.response.data ? `, details=${JSON.stringify(error.response.data)}` : ""}`;
  console.error(errorMsg);
  if (typeof window !== "undefined" && typeof window.alert === "function") {
    window.alert(errorMsg);
  }
  */
  return Promise.reject(error);
});


export default api;

export const callQueryLambda = async (endpoint, options) => {
  const queryLambdaURL = config.queryLambdaURL
  console.log("query lambda url: ", queryLambdaURL)

  try {
    const response = await axios({
      method: 'POST',
      ...options,
      baseURL: queryLambdaURL,
      url: endpoint,
      timeout: 30000,
      headers: {
        ...options.headers,
        Authorization: `Bearer V8qnelCt3LIn4Gi6`
      }
    });

    return response;
  } catch (error) {
    console.error(`Error calling new Lambda: ${error}`);
    throw error;
  }
};
