import { Factory } from 'rosie';
import { User } from "../models/User.js";
import ScriptQuestionFactory from "./scriptQuestionFactory.js";

const UserFactory = new Factory()
  .sequence('userName', (i) => `user${i}`) // Auto-incrementing user names
  .attr('entityType', 'user')
  .attr('organizationId', 'TESTORG')
  .attr('email', 'testuser@email.com')
  .attr('roleChatbotAdmin', false)
  .attr('roleDeveloperAdmin', false)
  .attr('roleSuperAdmin', false)
  .attr('version', 1)
  .after((user, options) => {
    return User.fromObject(user);
  });

UserFactory.newUser = function(data) {
  const defaultData = {
    organizationId: 'UMD',
    userName: '',
    email: '',
    roleChatbotAdmin: false,
    roleDeveloperAdmin: false,
    roleSuperAdmin: false,
    version: 1,
    answerText: ''
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default UserFactory;
