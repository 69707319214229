import { Factory } from 'rosie';
import { ShortEmbedding } from '../models/ShortEmbedding.js';
import { v4 as uuidv4 } from 'uuid';

const ShortEmbeddingFactory = new Factory()
  .attr('uuid', () => uuidv4()) // Generates a unique UUID for each script question
  .attr('version', '1') // Default version; adjust as needed
  .attr('organizationId', 'org1') // Default organization ID; adjust as needed
  .attr('chatbotId', 'bot1') // Default chatbot ID; adjust as needed
  .attr('sourcetype', '') // Default intent source type; adjust as needed
  .attr('intent', 'some_pecific_topic') // Default intent name; adjust as needed
  .attr('title', 'Filename_of_source_document') // Default source title; adjust as needed
  .attr('documentSubstring', 'The first X characters of the text from the chunk is here and may be trunca') // Default chunk text substring; adjust as needed
  .after((shortEmbedding, options) => {
    return ShortEmbedding.fromObject(shortEmbedding);
  });

  ShortEmbeddingFactory.newScriptQuestion = function(data) {
  const defaultData = {
    organizationId: 'UMD',
    chatbotId: '',
    uuid: '',
    sourcetype: '',
    intent: '',
    title: '',
    docsubstring: ''
  };

  const mergedData = { ...defaultData, ...data };

  return this.build(mergedData);
};

export default ShortEmbeddingFactory;
